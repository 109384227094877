import React from "react";
import Layout from "../components/layout";
import StoreSubmissionForm from "../components/StoreSubmissionForm";
import PageHeader from "../components/pageHeader";
import SEO from "../components/seo";

export default function list() {
  return (
    <Layout>
      <SEO
        keywords={[`find local fruit box`, `find local vegetable box`]}
        title="Suggest a business"
      />
      <PageHeader>
        Suggest a <span className="text-green-600">fruit and vegetable</span>{" "}
        box business
      </PageHeader>
      <div className="mt-16 sm:mt-32 border-gray-300 p-8">
        <StoreSubmissionForm />
      </div>
    </Layout>
  );
}
