import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "./button";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      fontFamily: "Raleway",
      "& fieldset": {
        borderColor: "#cbd5e0",
      },
      "&:not(.Mui-focused):hover fieldset": {
        borderColor: "#cbd5e0",
      },
    },
  },
})(TextField);

const StoreSubmissionForm = () => {
  return (
    <form
      className="max-w-3xl mx-auto"
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <ul>
        <h2 className="font-bold mb-4">Details</h2>
        <FormLi>
          <ListTextField name="name" label="Business name" />
        </FormLi>
        <FormLi>
          <ListTextField name="website" label="Website" />
        </FormLi>
        <FormLi>
          <ListTextField name="phone" label="Phone number" />
        </FormLi>
        <FormLi>
          <ListTextField name="email" label="Email" type="email" />
        </FormLi>
      </ul>
      <ul>
        <h2 className="font-bold mb-4">Social</h2>
        <FormLi>
          <ListTextField name="facebook" label="Facebook" />
        </FormLi>
        <FormLi>
          <ListTextField name="twitter" label="Twitter" />
        </FormLi>
        <FormLi>
          <ListTextField name="instagram" label="Instagram" />
        </FormLi>
      </ul>
      <div className="flex justify-end">
        <Button size="large" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
};

function FormLi({ children, ...props }) {
  return (
    <li className="mb-4 flex" {...props}>
      {children}
    </li>
  );
}

function ListTextField({ ...props }) {
  return <CssTextField variant="outlined" className="w-full" {...props} />;
}

export default StoreSubmissionForm;
