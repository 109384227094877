import React from "react";

export default function pageHeader({ children }) {
  return (
    <header className="sm:mt-20 mt-16 px-2">
      <h1 className="sm:text-4xl text-2xl font-bold max-w-sm sm:max-w-lg mx-auto text-center leading-tight">
        {children}
      </h1>
    </header>
  );
}
