import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const ColorButton = withStyles(theme => ({
  root: {
    color: `#0F4D4A`,
    backgroundColor: `#55D3B8`,
    "&:hover": {
      backgroundColor: `#55D3B8`
    },
    fontFamily: `Raleway`,
    fontWeight: `bold`,
    textTransform: `none`
  }
}))(Button);

export default function button({ children, ...props }) {
  return (
    <ColorButton {...props} color="primary" variant="contained">
      {children}
    </ColorButton>
  );
}
